
const initialState = {
    cardId:""
}

const Card = (state =initialState, action) => {
    switch (action.type) {
        case 'SET_CARDID': {
            return {
                ...state,
                cardId: action.payload
            }
        }
        case 'RESET_STATE' : {
            return { 
                ...state,
                cardId: ""
            }
        }
        default : {
            return state ;
        }
    }
}


export default Card;