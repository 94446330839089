import React, { Suspense } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from './utils/theme';
import AppRoutes from './routes/mainRoutes';
import './styles/globals.scss';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense>
        <AppRoutes />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
