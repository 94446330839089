const BASE_URL = "https://api.publicapis.org";
const PAYMENT_BASE_URL = 'https://mvv-api.teamdaze.nl/api/v1';

export default {
  entriesEndpoint: `${BASE_URL}/entries`,
  paymentMethodsEndpoint: `${PAYMENT_BASE_URL}/payment/methods/all`,
  startTopUpTranscationEndpoint: `${PAYMENT_BASE_URL}/payment/topupTransaction/start`,
  getTopUpTransactionStatus: `${PAYMENT_BASE_URL}/payment/transaction/status/`,
  paymentRefundTransactionEndpoint: `${PAYMENT_BASE_URL}/payment/refund/request`,
  getRFIDCreditEndPoint: `${PAYMENT_BASE_URL}/payment/rfid/balance/`,

  tokenType: "Bearer",

  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",
};