// ** Redux Imports
import rootReducer from "./rootReducer"
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit"

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    // if you do not want to persist this part of the state
    // whitelist: ['card'],
};

const reducer = combineReducers(rootReducer);
// const persistedReducer = persistReducer(reducer);
const store = configureStore({
    reducer: reducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        })
    }
})

export { store }
