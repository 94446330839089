import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
const NotFound = React.lazy(() => import("../pages/notFound/404"));
const Home = React.lazy(() => import("../pages/home/index"));
const Dashboard = React.lazy(() =>
  import("../pages/popupDashboard/popupDashboard")
);
const ErrorComponent = React.lazy(() => import("../components/errorPage"));

function AppRoutes() {
  return (
    <>
      <Routes>
        <Route exact path="/:cardid" element={<Dashboard />} />
        <Route exact path="/transaction" element={<Dashboard />} />
        <Route exact path="/error-page" element={<ErrorComponent />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/:id"  replace />}/>
      </Routes>
    </>
  );
}
export default AppRoutes;
