import axios from 'axios';
import jwtDefaultConfig from './jwtDefaultConfig';

class JwtService {
  jwtConfig = { ...jwtDefaultConfig };

  isAlreadyFetchingAccessToken = false;

  subscribers = [];

  constructor() {
    axios.interceptors.request.use(
      (config) => {
        const accessToken = this.getToken();

        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // console.log(error);
        const { config, response } = error;
        const originalRequest = config;

        // ** if (status === 401) {
        // if (response && response.status === 401) {
        //   // ** refreshToken not needed on these urls
        //   if (
        //     originalRequest.url.includes('/auth/user/login') ||
        //     originalRequest.url.includes('/auth/refreshToken')
        //   ) {
        //     return Promise.reject(error);
        //   } else if (!this.isAlreadyFetchingAccessToken) {
        //     this.isAlreadyFetchingAccessToken = true;
        //     this.refreshToken()
        //       .then((r) => {
        //         console.log(`refresh response ${r.response.statsuCode}`);
        //         this.isAlreadyFetchingAccessToken = false;
        //         this.setToken(r.data.accessToken);
        //         this.setRefreshToken(r.data.refreshToken);
        //         this.onAccessTokenFetched(r.data.accessToken);
        //       })
        //       .catch((e) => {
        //         console.log(`refresh catch ${e}`);
        //         this.isAlreadyFetchingAccessToken = false;
        //       });
        //   } else {
        //     const retryOriginalRequest = new Promise((resolve) => {
        //       this.addSubscriber((accessToken) => {
        //         this.isAlreadyFetchingAccessToken = false;
        //         originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        //         resolve(this.axios(originalRequest));
        //       });
        //     });
        //     return retryOriginalRequest;
        //   }
        // }
        return Promise.reject(error);
      }
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  getAllEntries() {
    return axios.get(this.jwtConfig.entriesEndpoint);
  }

  getPaymentMethods() {
    return axios.get(this.jwtConfig.paymentMethodsEndpoint);
  }
  startTopUpTranscation(data) {
    return axios.post(this.jwtConfig.startTopUpTranscationEndpoint, data);
  }
  getTopUpTransactionStatus(id) {
    return axios.get(`${this.jwtConfig.getTopUpTransactionStatus}${id}`);
  }
  paymentRefundTransaction(data) {
    return axios.post(this.jwtConfig.paymentRefundTransactionEndpoint, data);
  }
  getRfidCardCredit(id) {
    return axios.get(`${this.jwtConfig.getRFIDCreditEndPoint}${id}`);
  }
}

const jwt = new JwtService();
export default jwt;
